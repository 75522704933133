import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {MDXProvider} from '@mdx-js/react';

import Main from '../templates/Main';
import Hero from '../blocks/hero.mdx';
import ForDevelopers from '../blocks/for-developers.mdx';
import TryToday from '../blocks/TryToday';
import Benefits from '../blocks/benefits';
import Solutions from '../blocks/solutions';
import Security from '../blocks/security/index.mdx';
import HowItWorks from '../blocks/howitworks';
import mdxComponents from '../templates/Main/mdxComponents';
import {SecondaryBg} from '../templates/Main/styled';
import theme, {styled} from '../theme';
import DemoForm from '../components/DemoForm';

const PaddedContainer = styled(Container)`
  padding-top: ${({theme}) => theme.spacing(10)};

  * a.anchor[href] {
    display: none;
  }
`;

export function IndexPage() {
  return (
    <Main contained={false}>
      <MDXProvider components={mdxComponents}>
        <PaddedContainer style={{paddingTop: theme.spacing(4)}}>
          <Hero />
        </PaddedContainer>
        <SecondaryBg>
          <PaddedContainer>
            <ForDevelopers />
          </PaddedContainer>
        </SecondaryBg>
        <PaddedContainer>
          <Benefits />
        </PaddedContainer>
        <SecondaryBg>
          <PaddedContainer>
            <TryToday />
          </PaddedContainer>
        </SecondaryBg>
        <PaddedContainer style={{paddingLeft: 0, paddingRight: 0}}>
          <Solutions />
        </PaddedContainer>
        <SecondaryBg>
          <PaddedContainer>
            <HowItWorks />
          </PaddedContainer>
        </SecondaryBg>
        <PaddedContainer>
          <Security />
        </PaddedContainer>
      </MDXProvider>
    </Main>
  );
}

export default IndexPage;
